import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/AgentAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../../common/Modal";
import { CopyToClipboard } from "../../../../utils/CommonHelper";
import SweetAlert2 from "react-sweetalert2";
import { LinkTelegramForm } from "./LinkTelegramForm";

export const LinkTelegramDialog = ({ selMemberId }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [swalProps, setSwalProps] = useState({});
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  const saveValues = (queryParams) => {
    console.log(queryParams);
    dispatch(actions.linkTelegramID(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "linkSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <Modal
        modalId="LinkTelegramModal"
        title={intl.formatMessage({ id: "linkTelegramID" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          <LinkTelegramForm
            formikRef={formikRef}
            selMemberId={selMemberId}
            saveValues={saveValues}
          />
        </div>

        <div className="p-2 d-flex justify-content-end align-items-center">
          <button
            type="button"
            className="btn btn-primary px-3"
            onClick={() => formikRef.current.submitForm()}
          >
            {intl.formatMessage({ id: "submit" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
