import { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./agentList.css";
import { useAuth } from "../../auth/components/AuthInit";
import * as actions from "../redux/AgentAction";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateMemberDialog } from "../../memberlist/components/CreateMemberDialog";
import { TopupAndWithdrawDialog } from "./TopupAndWithdrawDialog";
import { CreateAgentDialog } from "./CreateAgentDialog";
import { Link } from "react-router-dom";
import { AgentGameList } from "./AgentGameList";
import { AgentDetails } from "./AgentDetails";
import { MemberList } from "../../memberlist/components/MemberList";
import { AgentFeeChargeList } from "./AgentFeeCharge";
import { AgentLotteryList } from "./AgentLotteryList";
import { ShareDialog } from "./ShareModal";
import { Dialog } from "@mui/material";
import { LinkTelegramDialog } from "./linkTelegram/LinkTelegramDialog";
export const AgentList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [selAgentId, setSelAgentId] = useState(null);
  const [selMemberId, setSelMemberId] = useState(null);
  const [selUsername, setSelUsername] = useState(null);
  const [selNickname, setSelNickname] = useState(null);
  const [selBalance, setSelBalance] = useState(null);
  const [selQuota, setSelQuota] = useState(null);
  const [topUpLine, setTopUpLine] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [currentAgentId, setCurrentAgentId] = useState(null);
  const [backToPagination, setBackToPagination] = useState(null);
  const [currentMemberPage, setCurrentMemberPage] = useState(1);
  const [currentAgentPage, setCurrentAgentPage] = useState(1);
  const [agentDetailsVisible, setAgentDetailsVisible] = useState(false);
  const [memberDetailsVisible, setMemberDetailsVisible] = useState(false);
  // const [agentFeeChargeVisible, setAgentFeeChargeVisible] = useState(false);
  const [agentLotteryVisible, setAgentLotteryVisible] = useState(false);
  const [pathFromSearch, setPathFromSearch] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [memberSettingPermission, setMemberSettingPermission] = useState("");
  const [agentSettingPermission, setAgentSettingPermission] = useState("");
  const [creditControlPermission, setCreditControlPermission] = useState("");
  const [memberBalancePermission, setMemberBalancePermission] = useState("");
  const [fromSide, setFromSide] = useState("");
  const dispatch = useDispatch();
  const { auth, saveAuth } = useAuth();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    const initialAgentId = location.state?.agentId || auth.agentid;
    const returnAgentId = location.state?.returnAgentId;
    const returnUsername = location.state?.returnUsername;
    const initialUsername = location.state?.username || auth.username;
    const backToPagination = location.state?.backToPagination || 0;
    const backToAgentPagination = location.state?.backToAgentPagination || 0;
    const savedScrollPosition = location.state?.scrollPosition || 0;
    const pathFromSearch = location.state?.searchName;
    const searchUsername = location.state?.searchUsername;

    setPathFromSearch(pathFromSearch);
    setBackToPagination(backToPagination);
    setSearchUsername(searchUsername);
    if (backToPagination != null && backToPagination != "") {
      setCurrentMemberPage(backToPagination + 1);
    }
    if (backToAgentPagination != null && backToAgentPagination != "") {
      setCurrentAgentPage(backToAgentPagination + 1);
    }

    if (savedScrollPosition) {
      window.scrollTo(0, savedScrollPosition);
    }
    if (
      returnAgentId &&
      returnAgentId !== "undefined" &&
      returnAgentId !== ""
    ) {
      setCurrentAgent(returnUsername);
      setCurrentAgentId(returnAgentId);
      const queryParams = {
        action: "agentmemberlist",
        selagentid: returnAgentId,
        agentpagenum: backToAgentPagination,
        memberpagenum: backToPagination,
      };
      dispatch(actions.callAgentList(queryParams)).then((resp) => {
        resp = resp.data;
        setTopUpLine(resp.agentUplineLists);
      });
    } else {
      setCurrentAgentId(initialAgentId);
      setCurrentAgent(initialUsername);
      const queryParams = {
        action: "agentmemberlist",
        selagentid: initialAgentId,
        agentpagenum: backToAgentPagination,
        memberpagenum: backToPagination,
      };
      dispatch(actions.callAgentList(queryParams)).then((resp) => {
        resp = resp.data;
        setTopUpLine(resp.agentUplineLists);
      });
    }
  }, [location.state, auth.agentid, auth.username, dispatch, backToPagination]);

  const handleClick = (agentId, username) => {
    setCurrentAgent(username);
    setCurrentAgentId(agentId);
    const queryParams = {
      action: "agentmemberlist",
      selagentid: agentId,
      agentpagenum: "0",
      memberpagenum: "0",
    };

    dispatch(actions.callAgentList(queryParams))
      .then((resp) => {
        resp = resp.data;
        setTopUpLine(resp.agentUplineLists);
      })
      .catch((error) => {
        console.error("Error fetching agent list:", error);
      });
  };
  const handlePageClick = (pageNumber) => {
    setCurrentAgentPage(pageNumber);
    const queryParams = {
      action: "agentmemberlist",
      selagentid: currentAgentId,
      agentpagenum: pageNumber - 1,
      memberpagenum: currentMemberPage - 1,
    };
    dispatch(actions.callAgentList(queryParams)).then((resp) => {
      resp = resp.data;
      setTopUpLine(resp.agentUplineLists);
    });
  };
  const handlePageClick1 = (pageNumber) => {
    setCurrentMemberPage(pageNumber);
    const queryParams = {
      action: "agentmemberlist",
      selagentid: currentAgentId,
      agentpagenum: currentAgentPage - 1,
      memberpagenum: pageNumber - 1,
    };
    dispatch(actions.callAgentList(queryParams)).then((resp) => {
      resp = resp.data;
      setTopUpLine(resp.agentUplineLists);
    });
  };

  const handleNavigate = (path, state) => {
    const scrollPosition = window.scrollY;
    navigate(path, {
      state: {
        ...state,
        scrollPosition,
      },
    });
  };
  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );
    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "membersetting":
            setMemberSettingPermission(permission.allow);
            break;
          case "agentsetting":
            setAgentSettingPermission(permission.allow);
            break;
          case "creditcontrol":
            setCreditControlPermission(permission.allow);
            break;
          case "memberbalancecontrol":
            setMemberBalancePermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handlNew = () => {
    window.open(redirectAgent);
    setOpen(false);
  };

  return (
    <div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <ShareDialog
        selAgentId={selAgentId}
        selMemberId={selMemberId}
        fromSide={fromSide}
      />
      <CreateAgentDialog setSwalProps={setSwalProps} />
      <CreateMemberDialog setSwalProps={setSwalProps} />
      <AgentGameList selAgentId={selAgentId} selUsername={selUsername} />
      <AgentDetails
        selAgentId={selAgentId}
        isAgentDetailsVisible={agentDetailsVisible}
        currentAgentId={currentAgentId}
        currentAgentPage={currentAgentPage}
        agentSettingPermission={agentSettingPermission}
        onClose={() => setAgentDetailsVisible(false)}
      />
      <MemberList
        selMemberId={selMemberId}
        currentAgentId={currentAgentId}
        currentMemberPage={currentMemberPage}
        isMemberDetailsVisible={memberDetailsVisible}
        memberSettingPermission={memberSettingPermission}
        onClose={() => setMemberDetailsVisible(false)}
      />
      <TopupAndWithdrawDialog
        setSwalProps={setSwalProps}
        currentAgentPage={currentAgentPage}
        currentMemberPage={currentMemberPage}
        currentAgentId={currentAgentId}
        selAgentId={selAgentId}
        selUsername={selUsername}
        selNickname={selNickname}
        selBalance={selBalance}
        selQuota={selQuota}
      />
      {/* <AgentFeeChargeList
        setSwalProps={setSwalProps}
        selAgentId={selAgentId}
        isAgentFeeChargeVisible={agentFeeChargeVisible}
        selUsername={selUsername}
        onClose={() => setAgentFeeChargeVisible(false)}
      /> */}
      <AgentLotteryList
        setSwalProps={setSwalProps}
        currentAgentId={currentAgentId}
        selAgentId={selAgentId}
        selUsername={selUsername}
        pathFromSearch={pathFromSearch}
        searchUsername={searchUsername}
        isAgentLotteryVisible={agentLotteryVisible}
        onClose={() => setAgentLotteryVisible(false)}
      />
      <LinkTelegramDialog selMemberId={selMemberId} />
      <div className="d-flex align-items-center">
        {pathFromSearch == "yes" && (
          <h2>
            <i
              className="bi bi-chevron-left"
              onClick={() => {
                navigate("/searchByUsername", {
                  state: {
                    searchUsername: searchUsername,
                  },
                });
              }}
            ></i>
          </h2>
        )}
        <h2>{intl.formatMessage({ id: "accountList" })}</h2>
      </div>

      {agentState && agentState.agentLists && (
        <div>
          {intl.formatMessage({ id: "telegramInviteLink" }) + " : "}
          <a
            href={agentState.agentLists.telegraminvitelink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "#0080FF" }} // Color blue for link styling
          >
            {agentState.agentLists.telegraminvitelink}
          </a>
        </div>
      )}
      <div
        className="buttonCss"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <nav>
            <ol className="breadcrumb">
              {topUpLine && topUpLine.length > 0 ? (
                <>
                  <li>
                    {topUpLine
                      .slice()
                      .reverse()
                      .map((agent, index) => (
                        <>
                          <span
                            key={index}
                            onClick={() =>
                              handleClick(agent.agentid, agent.username)
                            }
                          >
                            {index > 0 && " / "}
                            <span style={{ textDecoration: "underline" }}>
                              {agent.username}
                            </span>
                          </span>
                        </>
                      ))}
                  </li>
                </>
              ) : (
                <li>
                  <span
                    onClick={() => handleClick(auth.agentid, auth.username)}
                  >
                    {auth.username}
                  </span>
                </li>
              )}
              {topUpLine && topUpLine.length > 0 && (
                <li>
                  <span className="breadcrumb-separator">/</span>
                  <span>{currentAgent}</span>
                </li>
              )}
            </ol>
          </nav>
        </div>

        {agentSettingPermission === "true" && (
          <>
            <button
              className="btn btn-primary buttonAdd"
              data-bs-toggle="modal"
              data-bs-target={`#addAgentModal`}
            >
              {intl.formatMessage({ id: "addAgent" })}
            </button>
          </>
        )}
      </div>
      <div
        className="agent-div"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="agent-container" style={{ overflowX: "auto" }}>
          <table className="agent-table">
            <thead className="agent-header">
              <tr className="agent-row">
                <th className="agent-cell hide-on-mobile col-1">
                  {intl.formatMessage({ id: "no" })}
                </th>
                <th className="agent-cell col-2 fixed-column">
                  {intl.formatMessage({ id: "username" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "nickname" })}
                </th>
                <th className="agent-cell agent-col text-end">
                  {intl.formatMessage({ id: "balance" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "status" })}
                </th>
                <th className="agent-cell agent-col text-center">
                  {intl.formatMessage({ id: "share" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "action" })}
                </th>
              </tr>
            </thead>
            <tbody className="agent-body">
              {agentState.agentLists?.agentList?.agentLists ? (
                agentState.agentLists.agentList.agentLists.length > 0 ? (
                  agentState.agentLists.agentList.agentLists.map(
                    (agent, index) => (
                      <tr key={index} className="agent-row">
                        <td className="agent-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="agent-cell col-2 fixed-column">
                          <button
                            className="buttonName"
                            onClick={() =>
                              handleClick(agent.agentid, agent.username)
                            }
                          >
                            {agent.username}
                          </button>
                        </td>
                        <td className="agent-cell col-2">{agent.nickname}</td>
                        <td className="agent-cell agent-col text-end">
                          {agent.balance}
                        </td>
                        <td className="agent-cell col-2">{agent.status}</td>

                        <td className="agent-cell agent-col text-center">
                          {agent.ispasswordnew === "true" && (
                            <i
                              className="bi bi-share"
                              data-bs-toggle="modal"
                              data-bs-target={`#ShareModal`}
                              onClick={() => {
                                setSelAgentId(agent.agentid);
                                setFromSide("agent");
                              }}
                            ></i>
                          )}
                        </td>

                        <td className="agent-cell col-2 d-flex">
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "#00FFFF",
                              borderColor: "#00FFFF",
                              color: "black",
                            }}
                            onClick={() => {
                              setSelAgentId(agent.agentid);
                              setAgentDetailsVisible(true);
                            }}
                          >
                            <i className="bi bi-exclamation-circle" />
                          </button>
                          {creditControlPermission === "true" && (
                            <>
                              <button
                                className="btn btn-primary me-1"
                                style={{
                                  backgroundColor: "#00FF00",
                                  borderColor: "#00FF00",
                                  color: "black",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#TopupAndWithdrawModal"
                                onClick={() => {
                                  setSelAgentId(agent.agentid);
                                  setSelUsername(agent.username);
                                  setSelNickname(agent.nickname);
                                  setSelBalance(agent.balance);
                                  setSelQuota(agent.acctquota);
                                }}
                              >
                                <i className="bi bi-wallet2" />
                              </button>
                              <button
                                className="btn btn-primary me-1"
                                style={{
                                  backgroundColor: "#FFFF00",
                                  borderColor: "#FFFF00",
                                  color: "black",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target={`#GameListModal`}
                                onClick={() => {
                                  setSelAgentId(agent.agentid);
                                  setSelUsername(agent.username);
                                }}
                              >
                                <i className="bi bi-list-ul" />
                              </button>
                            </>
                          )}
                          {agentSettingPermission === "true" && (
                            <>
                              <button
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "#0080FF",
                                  borderColor: "#0080FF",
                                  color: "black",
                                }}
                                onClick={() => {
                                  setSelAgentId(agent.agentid);
                                  setSelUsername(agent.username);
                                  setAgentLotteryVisible(true);
                                }}
                              >
                                <i className="bi bi-4-circle" />
                              </button>
                            </>
                          )}
                          {/* <button
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#0080FF",
                              borderColor: "#0080FF",
                              color: "black",
                            }}
                            onClick={() => {
                              setSelUsername(agent.username);
                              setSelAgentId(agent.agentid);
                              setAgentFeeChargeVisible(true);
                            }}
                          >
                            <i className="bi bi-diagram-2" />
                          </button> */}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr className="oRecordDiv">
                    <td colSpan="5">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="5">
                    <Skeleton height="600px" className="w-100" />
                  </td>
                </tr>
              )}
            </tbody>
            <div className="pagination-container">
              {agentState.agentLists &&
                agentState.agentLists.agentList &&
                Array.from(
                  { length: agentState.agentLists.agentList.totalpage },
                  (_, i) => i + 1
                ).map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`pagination-button ${
                      currentAgentPage === pageNumber ? "active" : ""
                    }`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
            </div>
          </table>
        </div>

        <div
          className="buttonCss"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {intl.formatMessage({ id: "memberList" })}
          {memberSettingPermission === "true" && (
            <>
              <button
                className="btn btn-primary buttonAdd"
                data-bs-toggle="modal"
                data-bs-target={`#addMemberModal`}
              >
                {intl.formatMessage({ id: "addMember" })}
              </button>
            </>
          )}
        </div>
        <div className="agent-container" style={{ overflowX: "auto" }}>
          <table className="agent-table" style={{ minWidth: "400px" }}>
            <thead className="agent-header">
              <tr className="agent-row">
                <th className="agent-cell hide-on-mobile col-1">
                  {intl.formatMessage({ id: "no" })}
                </th>
                <th className="agent-cell col-4 fixed-column">
                  {intl.formatMessage({ id: "username" })}
                </th>
                <th className="agent-cell col-4">
                  {intl.formatMessage({ id: "nickname" })}
                </th>
                <th className="agent-cell agent-col text-center">
                  {intl.formatMessage({ id: "share" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "action" })}
                </th>
              </tr>
            </thead>
            <tbody className="agent-body">
              {agentState.agentLists &&
              agentState.agentLists.memberList &&
              agentState.agentLists.memberList.memberLists ? (
                agentState.agentLists.memberList.memberLists.length > 0 ? (
                  agentState.agentLists.memberList.memberLists.map(
                    (member, index) => (
                      <tr key={index} className="agent-row">
                        <td className="agent-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="agent-cell col-4 fixed-column">
                          {member.username}
                        </td>
                        <td className="agent-cell col-4">{member.nickname}</td>

                        <td className="agent-cell agent-col text-center">
                          {member.ispasswordnew === "true" && (
                            <i
                              className="bi bi-share"
                              data-bs-toggle="modal"
                              data-bs-target={`#ShareModal`}
                              onClick={() => {
                                setSelMemberId(member.memberid);
                                setFromSide("member");
                              }}
                            ></i>
                          )}
                        </td>
                        <td className="agent-cell col-2 d-flex">
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "#00FFFF",
                              borderColor: "#00FFFF",
                              color: "black",
                            }}
                            onClick={() => {
                              setSelMemberId(member.memberid);
                              setMemberDetailsVisible(true);
                            }}
                          >
                            <i className="bi bi-exclamation-circle" />
                          </button>
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "#00FF00",
                              borderColor: "#00FF00",
                              color: "black",
                            }}
                            onClick={() =>
                              handleNavigate(
                                `/memberList/gameList/${member.memberid}`,
                                {
                                  state: {
                                    userName: member.username,
                                    pagination: currentMemberPage,
                                    memberBalancePermission:
                                      memberBalancePermission,
                                    currentAgentId: currentAgentId,
                                    currentUsername: currentAgent,
                                  },
                                }
                              )
                            }
                          >
                            <i className="bi bi-controller" />
                          </button>
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "#FFFF00",
                              borderColor: "#FFFF00",
                              color: "black",
                            }}
                            onClick={() => {
                              handleNavigate(
                                `/memberList/memberReport/${member.memberid}`,
                                {
                                  state: {
                                    currentAgentId: currentAgentId,
                                    userName: member.username,
                                    pagination: currentMemberPage,
                                    currentUsername: currentAgent,
                                  },
                                }
                              );
                            }}
                          >
                            <i className="bi bi-clipboard2-data" />
                          </button>
                          <button
                            className="btn btn-primary me-1"
                            style={{
                              backgroundColor: "rgb(0, 128, 255)",
                              borderColor: "rgb(0, 128, 255)",
                              color: "black",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target={`#LinkTelegramModal`}
                            onClick={() => {
                              setSelMemberId(member.memberid);
                            }}
                          >
                            <i className="bi bi-link-45deg" />
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr className="oRecordDiv">
                    <td colSpan="5">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="5">
                    <Skeleton height="600px" className="w-100" />
                  </td>
                </tr>
              )}
            </tbody>
            <div className="pagination-container">
              {agentState.agentLists &&
                agentState.agentLists.memberList &&
                Array.from(
                  { length: agentState.agentLists.memberList.totalpage },
                  (_, i) => i + 1
                ).map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`pagination-button ${
                      currentMemberPage === pageNumber ? "active" : ""
                    }`}
                    onClick={() => handlePageClick1(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
            </div>
          </table>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div
          className="text-font3"
          style={{
            padding: "20px",
            backgroundColor: "#1c1e23",
            color: "white",
          }}
        >
          <p> {intl.formatMessage({ id: "openNewRedirectTab" })}</p>
          <div
            style={{ color: "#f5be09", textAlign: "end" }}
            onClick={handlNew}
          >
            {intl.formatMessage({ id: "confirm" })}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
