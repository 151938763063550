import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../../common/control/InputField";

export const LinkTelegramForm = ({ formikRef, saveValues, selMemberId }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        telegramid: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (values.telegramid != null && values.telegramid !== "") {
          let queryParams = {
            action: "embedtelegramid",
            telegramid: values.telegramid,
            memberid: selMemberId,
          };

          saveValues(queryParams); // Call saveValues with queryParams
        } else {
          formikHelpers.setFieldError(
            "telegramid",
            intl.formatMessage({ id: "telegramIdRequired" })
          );
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div
            className="my-2"
            style={{ borderBottom: "1px solid rgb(175, 164, 164, 0.2)" }}
          ></div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "telegramID" })}
            </label>

            <Field
              name="telegramid"
              component={Input}
              placeholder={intl.formatMessage({ id: "telegramID" })}
              autoComplete="off"
              type="text"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
