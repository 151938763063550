import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/RefundNPromotionAction";
import DateRangeComp from "../../common/DateRangeComp";
import { useNavigate } from "react-router-dom";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { formatInTimeZone } from "date-fns-tz";
import { ProceedDialog } from "./ProceedDialog";

export const MemberRefund = ({}) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const startOfYesterday = new Date(yesterday.setHours(0, 0, 0, 0));
  const endOfYesterday = new Date(yesterday.setHours(23, 59, 59, 999));
  const [fromDate, setFromDate] = useState(startOfYesterday);
  const [toDate, setToDate] = useState(endOfYesterday);
  const [selectedDirectionBaseOn, setSelectedDirectionBaseOn] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDirectionNum, setSelectedDirectionNum] = useState("");
  const [selectedRefundType, setSelectedRefundType] = useState("");
  const [selectedRefundNumber, setSelectedRefundNumber] = useState("");
  const [encrypytString, setEncrypytString] = useState("");
  const timeZone = "Asia/Kuala_Lumpur";
  const refundNPromotionState = useSelector(
    ({ refundNPromotion }) => refundNPromotion,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "memberrefundlist_count",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
      directionbaseon: "",
      direction: "",
      directionnum: "",
      refundtype: "",
      refundnumber: "",
    };
    dispatch(actions.callMemberRefundList(queryParams)).then((resp) => {
      resp = resp.data;
      setSelectedDirectionBaseOn(resp.directionBaseOnLists[0].code);
      setSelectedDirection(resp.directionLists[0].code);
      setSelectedRefundType(resp.refundTypeLists[0].code);
      setSelectedDirectionNum(resp.directionnum);
      setSelectedRefundNumber(resp.refundnumber);
    });
  }, []);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchClick = () => {
    const queryParams = {
      action: "memberrefundlist_count",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
      directionbaseon: selectedDirectionBaseOn,
      direction: selectedDirection,
      directionnum: selectedDirectionNum,
      refundtype: "",
      refundnumber: "",
    };
    dispatch(actions.callMemberRefundList(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const customCardClass =
    "rounded align-items-center justify-content-spacebetween py-2 px-5";

  const handleRefundNumChange = (e) => {
    setSelectedRefundNumber(e.target.value);
  };

  const handleDirectionNumChange = (e) => {
    setSelectedDirectionNum(e.target.value);
  };

  const handleCalculateClick = () => {
    const queryParams = {
      action: "memberrefundlist_count",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
      directionbaseon: selectedDirectionBaseOn,
      direction: selectedDirection,
      directionnum: selectedDirectionNum,
      refundtype: selectedRefundType,
      refundnumber: selectedRefundNumber,
    };
    dispatch(actions.callMemberRefundList(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <ProceedDialog
        setSwalProps={setSwalProps}
        encrypytString={encrypytString}
      />
      <div>
        <h2>{intl.formatMessage({ id: "memberRefund" })}</h2>
      </div>
      <div className="d-flex justify-content-between">
        <DateRangeComp
          initialRange={[
            { startDate: fromDate, endDate: toDate, key: "selection" },
          ]}
          onDateChange={handleDateChange}
        />
        <div style={{ margin: "auto 0" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleSearchClick();
            }}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      <div className="my-2">
        {refundNPromotionState && refundNPromotionState.memberRefundList && (
          <div>
            {intl.formatMessage({ id: "direction" })}
            <div
              className="mb-2"
              style={{ borderBottom: "1px solid rgba(175, 164, 164, 0.4)" }}
            >
              <select
                className="dropdown respond-Select"
                value={selectedDirectionBaseOn}
                onChange={(e) => {
                  setSelectedDirectionBaseOn(e.target.value);
                }}
              >
                {refundNPromotionState.memberRefundList.directionBaseOnLists.map(
                  (value, index) => (
                    <option key={index} value={value.code}>
                      {value.name}
                    </option>
                  )
                )}
              </select>
              <select
                className="dropdown respond-Select"
                value={selectedDirection}
                onChange={(e) => setSelectedDirection(e.target.value)}
              >
                {refundNPromotionState.memberRefundList.directionLists.map(
                  (value, index) => (
                    <option key={index} value={value.code}>
                      {value.name}
                    </option>
                  )
                )}
              </select>
              <input
                type="number"
                className="refund-Input"
                placeholder={
                  refundNPromotionState.memberRefundList.directionnum
                }
                onChange={handleDirectionNumChange}
              />
            </div>
            {intl.formatMessage({ id: "refundBy" })}
            <div className="d-flex justify-content-between">
              <div>
                <select
                  className="dropdown respond-Select"
                  value={selectedRefundType}
                  onChange={(e) => setSelectedRefundType(e.target.value)}
                >
                  {refundNPromotionState.memberRefundList.refundTypeLists.map(
                    (value, index) => (
                      <option key={index} value={value.code}>
                        {value.name}
                      </option>
                    )
                  )}
                </select>
                <input
                  type="number"
                  className="refund-Input"
                  placeholder={
                    refundNPromotionState.memberRefundList.refundnumber
                  }
                  onChange={handleRefundNumChange}
                />
              </div>

              <div className="d-flex">
                <div className="mx-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleCalculateClick();
                    }}
                  >
                    {intl.formatMessage({ id: "calculate" })}
                  </button>
                </div>

                {refundNPromotionState.memberRefundList.encrypytstring !==
                  "" && (
                  <div>
                    <button
                      className="btn btn-success"
                      data-bs-toggle="modal"
                      data-bs-target="#proceedModal"
                      onClick={() => {
                        setEncrypytString(
                          refundNPromotionState.memberRefundList.encrypytstring
                        );
                      }}
                    >
                      {intl.formatMessage({ id: "proceed" })}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "gameIn" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "gameOut" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-end text-nowrap">
                    {intl.formatMessage({ id: "gameProfit" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-end">
                    {intl.formatMessage({ id: "balanceInGame" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-end">
                    {intl.formatMessage({ id: "refundBalance" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-start">
                    {intl.formatMessage({ id: "condition" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {refundNPromotionState.memberRefundList &&
                refundNPromotionState.memberRefundList.memberRefundLists &&
                refundNPromotionState.memberRefundList.memberRefundLists
                  .length > 0 ? (
                  refundNPromotionState.memberRefundList.memberRefundLists.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-start">
                          {report.username}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.gamein}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.gameout}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.gameprofit}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.balanceingame}
                        </td>
                        <td
                          className="member-cell col-2 text-end"
                          style={{ color: "orange" }}
                        >
                          {report.refundbalance}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.condition}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="8">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                {refundNPromotionState &&
                  refundNPromotionState.memberRefundList && (
                    <tr
                      className="member-row1"
                      style={{
                        borderTop: "1px solid rgba(175, 164, 164, 0.4)",
                      }}
                    >
                      <td className="member-cell hide-on-mobile col-1"></td>
                      <td className="member-cell col-2 text-end"></td>
                      <td className="member-cell col-2 text-end"></td>
                      <td className="member-cell col-2 text-end"></td>
                      <td className="member-cell col-2 text-end"></td>
                      <td className="member-cell col-2 text-end">
                        {intl.formatMessage({ id: "total" }) + " : "}
                      </td>
                      <td
                        className="member-cell col-2 text-end"
                        style={{ color: "orange" }}
                      >
                        {refundNPromotionState.memberRefundList.totalrefund}
                      </td>
                      <td className="member-cell col-2 text-end"></td>
                    </tr>
                  )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
