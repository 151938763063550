import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkAgentList(params) {
  return authPost(`${baseUrl}/Agent2.ashx`, params);
}
export function checkAgentDetails(params) {
  return authPost(`${baseUrl}/Agent3.ashx`, params);
}
export function checkAgentCharge(params) {
  return authPost(`${baseUrl}/Agent7.ashx`, params);
}
export function checkAgent4D(params) {
  return authPost(`${baseUrl}/Agent8.ashx`, params);
}
export function checkStatusDownline(params) {
  return authPost(`${baseUrl}/Agent9.ashx`, params);
}
export function checkAccQuota(params) {
  return authPost(`${baseUrl}/Agent10.ashx`, params);
}
export function linkID(params) {
  return authPost(`${baseUrl}/Agent11.ashx`, params);
}
