import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { fetchIPAddress, login } from "../../api/loginapi";
import { Form, Formik, Field } from "formik";
import { LoginSchema } from "../../yupSchema/loginSchema";
import { useAuth } from "../AuthInit";
import { Input } from "../../../common/control/InputField";
import { CheckIsNull } from "../../../../utils/CommonHelper";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import { initializeApp } from "firebase/app";
// import { firebaseConfig } from "../../../../setup/FirebaseConfig";

export const LoginFormTab = ({ setSwalProps, setTempAuth }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const intl = useIntl();
  // const currentLocale = localStorage.getItem("locale");

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     longitude.current = position.coords.longitude;
  //     latitude.current = position.coords.latitude;
  //   });
  // }, []);

  const renderLoginComponent = () => {
    return (
      <Formik
        initialValues={{ username: "", userPassword: "" }}
        enableReinitialize={true}
        validationSchema={LoginSchema(intl)}
        onSubmit={(values, formikHelpers) => {
          setIsLoading(true);
          fetchIPAddress()
            .then((res) => {
              const queryParams = {
                action: "agentlogin",
                username: values.username,
                password: values.userPassword,
                language: intl.locale,
                fcmnotificationid: "",
                ipaddress: res.ip,
                projectid: "mmmcom",
                // projectdemolocal1
                // jstarproject81
                //mmmcom
                //mbna88
              };
              login(queryParams)
                .then((resp) => {
                  setIsLoading(false);
                  console.log(resp);
                  if (parseInt(resp.code) === 0) {
                    saveAuth(resp);
                    setCurrentUser(resp);
                    localStorage.setItem(
                      "permissions",
                      JSON.stringify(resp.agentPermissions)
                    );
                    localStorage.setItem("role", resp.role);
                  } else {
                    formikHelpers.setFieldValue("userPassword", "");
                    setSwalProps({
                      show: true,
                      icon: "error",
                      title: intl.formatMessage({ id: "error" }),
                      text: resp.message,
                    });
                  }
                })
                .catch((error) => {
                  setIsLoading(false);
                });
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
        }) => (
          <Form style={{ maxWidth: "800px", margin: "0 auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "2%",
              }}
            >
              <div
                style={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  width: "90%",
                  boxSizing: "border-box",
                }}
              >
                <label className="mb-2 text-input-label text-color">
                  {intl.formatMessage({ id: "username" })}
                </label>
                <div className="mb-2">
                  <Field
                    name="username"
                    component={Input}
                    placeholder={intl.formatMessage({ id: "username" })}
                    autoComplete="off"
                    pattern="[a-zA-Z0-9]*"
                  />
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  width: "90%",
                  boxSizing: "border-box",
                }}
              >
                <label className="mb-2 text-input-label text-color">
                  {intl.formatMessage({ id: "password" })}
                </label>
                <div className="mb-2">
                  <Field
                    name="userPassword"
                    component={Input}
                    placeholder={intl.formatMessage({ id: "password" })}
                    autoComplete="off"
                    type="password"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "2%",
              }}
            >
              <button
                type="submit"
                disabled={isLoading}
                style={{
                  width: "60%",
                  height: 35,
                  borderRadius: 15,
                  backgroundColor: "rgb(245, 190, 9)",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ scale: "0.75" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div>
                    <span style={{ flex: 1, textAlign: "center" }}>
                      {intl.formatMessage({ id: "login" })}
                    </span>
                  </div>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {renderLoginComponent()}
    </div>
  );
};
