import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { useLocation, useNavigate } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { formatInTimeZone } from "date-fns-tz";
import { useAuth } from "../../auth/components/AuthInit";
import "../../memberlist/components/MemberReport.css";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
export const AgentDownlineReportList = () => {
  const intl = useIntl();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [returnFromDate, setReturnFromDate] = useState(
    new Date(location.state?.fromDate)
  );
  const [returnToDate, setReturnToDate] = useState(
    new Date(location.state?.toDate)
  );
  const [currentAgent, setCurrentAgent] = useState("");
  const [currentAgentID, setCurrentAgentID] = useState("");
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";
  useEffect(() => {
    const currentAgentID = location.state?.currentAgentID || auth.agentid;
    const currentAgent = location.state?.currentAgent || auth.username;
    const fromDateStr = location.state?.fromDate;
    const toDateStr = location.state?.toDate;

    setCurrentAgentID(currentAgentID);
    setCurrentAgent(currentAgent);
    if (location.state == null) {
      const formattedFromDate = formatInTimeZone(
        fromDate,
        timeZone,
        "yyyy-MM-dd"
      );
      const formattedToDate = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
      const queryParams = {
        action: "allagentfcgamereportV2",
        fromdate: formattedFromDate,
        todate: formattedToDate,
        selagentid: currentAgentID,
      };
      dispatch(actions.agentDownlineReport(queryParams));
    } else {
      const fromDateParsed = new Date(fromDateStr);
      const toDateParsed = new Date(toDateStr);
      setFromDate(fromDateParsed);
      setToDate(toDateParsed);
      setReturnFromDate(fromDateParsed);
      setReturnToDate(toDateParsed);
      const formattedFromDate = formatInTimeZone(
        fromDateParsed,
        timeZone,
        "yyyy-MM-dd"
      );
      const formattedToDate = formatInTimeZone(
        toDateParsed,
        timeZone,
        "yyyy-MM-dd"
      );
      const queryParams = {
        action: "allagentfcgamereportV2",
        fromdate: formattedFromDate,
        todate: formattedToDate,
        selagentid: currentAgentID,
      };
      dispatch(actions.agentDownlineReport(queryParams));
    }
  }, [dispatch]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };
  const handleSearchClick = () => {
    const formattedFromDate = formatInTimeZone(
      fromDate,
      timeZone,
      "yyyy-MM-dd"
    );
    const formattedToDate = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
    const queryParams = {
      action: "allagentfcgamereportV2",
      fromdate: formattedFromDate,
      todate: formattedToDate,
      selagentid: currentAgentID,
    };
    dispatch(actions.agentDownlineReport(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  const handleClick = (agentId, username) => {
    setCurrentAgent(username);
    setCurrentAgentID(agentId);
    const formattedFromDate = formatInTimeZone(
      fromDate,
      timeZone,
      "yyyy-MM-dd"
    );
    const formattedToDate = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
    const queryParams = {
      action: "allagentfcgamereportV2",
      fromdate: formattedFromDate,
      todate: formattedToDate,
      selagentid: agentId,
    };
    dispatch(actions.agentDownlineReport(queryParams));
    // .then((resp) => {
    //   resp = resp.data;
    //   setTopUpLine(resp.agentUplineLists);
    // })
    // .catch((error) => {
    //   console.error("Error fetching agent list:", error);
    // });
  };
  const customCardClass =
    "rounded d-flex align-items-center justify-content-spacebetween py-2";

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "agentDownlineReport" })}</h2>
      </div>
      <div>
        <nav>
          <ol className="breadcrumb">
            {reportState &&
            reportState.agentDownlineReportLists &&
            reportState.agentDownlineReportLists.agentUplineLists &&
            reportState.agentDownlineReportLists.agentUplineLists.length > 0 ? (
              <>
                <li>
                  {reportState.agentDownlineReportLists.agentUplineLists
                    .slice()
                    .reverse()
                    .map((agent, index) => (
                      <>
                        <span
                          key={index}
                          onClick={() =>
                            handleClick(agent.agentid, agent.username)
                          }
                        >
                          {index > 0 && " / "}
                          <span style={{ textDecoration: "underline" }}>
                            {agent.username}
                          </span>
                        </span>
                      </>
                    ))}
                </li>
              </>
            ) : (
              <li>
                <span onClick={() => handleClick(auth.agentid)}>
                  {auth.username}
                </span>
              </li>
            )}
            {reportState &&
              reportState.agentDownlineReportLists &&
              reportState.agentDownlineReportLists.agentUplineLists &&
              reportState.agentDownlineReportLists.agentUplineLists.length >
                0 && (
                <li>
                  <span className="breadcrumb-separator">/</span>
                  <span>{currentAgent}</span>
                </li>
              )}
          </ol>
        </nav>
      </div>
      <div className="dateSearch-div">
        <div className="dateRange-div">
          {location.state === null ? (
            <DateRangeComp
              initialRange={[
                { startDate: fromDate, endDate: toDate, key: "selection" },
              ]}
              onDateChange={handleDateChange}
            />
          ) : (
            <DateRangeComp
              initialRange={[
                {
                  startDate: returnFromDate,
                  endDate: returnToDate,
                  key: "selection",
                },
              ]}
              onDateChange={handleDateChange}
            />
          )}
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>

      <div
        className="member-div"
        style={{
          margin: "10px auto",
        }}
      >
        {/* <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-3 text-start">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "totalBet" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "totalWin" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "totalProfit" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "action" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {reportState.agentDownlineReportLists &&
                reportState.agentDownlineReportLists.allAgentFcGameReports &&
                reportState.agentDownlineReportLists.allAgentFcGameReports
                  .length > 0 ? (
                  reportState.agentDownlineReportLists.allAgentFcGameReports.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-3 text-start">
                          <button
                            className="buttonName"
                            onClick={() =>
                              handleClick(report.agentid, report.username)
                            }
                          >
                            {report.username}
                          </button>
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.totalbet}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.totalwin}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.totalprofit}
                        </td>
                        <td className="member-cell col-3">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              navigate(
                                `/agentReport/agentDownlineReport/${report.agentid}`,
                                {
                                  state: {
                                    fromDate: fromDate.toISOString(),
                                    toDate: toDate.toISOString(),
                                    currentAgentID: currentAgentID,
                                    currentAgent: currentAgent,
                                  },
                                }
                              );
                            }}
                          >
                            {intl.formatMessage({ id: "details" })}
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                {reportState && reportState.agentDownlineReportLists && (
                  <tr
                    className="member-row1"
                    style={{ borderTop: "1px solid rgba(175, 164, 164, 0.4)" }}
                  >
                    <td className="member-cell hide-on-mobile col-1"></td>
                    <td
                      className="member-cell col-3"
                      style={{ textAlign: "end" }}
                    >
                      {intl.formatMessage({ id: "total" }) + " : "}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentDownlineReportLists.totalbet}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentDownlineReportLists.totalwin}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentDownlineReportLists.totalprofit}
                    </td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div> */}
        <div>
          {reportState && reportState.agentDownlineReportLists && (
            <div
              className={`${customCardClass} gamelistDiv1 cursor-pointer position-relative text-center mobile-overflow`}
              style={{
                border: "1px solid grey",
                padding: "5px",
                margin: "10px 0 ",
              }}
            >
              <div style={{ width: "100%" }}>
                <table
                  className="text-start table-report"
                  style={{ width: "100%", minWidth: "1000px" }}
                >
                  <tbody>
                    <tr
                      style={{
                        borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                      }}
                    >
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalTopup" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totaltopup}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "gatewayTopup" }) +
                          " : " +
                          reportState.agentDownlineReportLists
                            .paymentgatewaytopup}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalWithdraw" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalwithdraw}
                      </td>
                      <td className="col-3">
                        {intl.formatMessage({ id: "totalRefund" }) +
                          " : " +
                          reportState.agentDownlineReportLists.refundtopup}
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                      }}
                    >
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalGameIn" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalin}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalGameOut" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalout}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalGameProfit" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalprofit}
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                      }}
                    >
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalMktBet" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalmktbet}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalMktWin" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalmktwin}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalHlBet" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalhlbet}
                      </td>
                      <td className="col-3">
                        {intl.formatMessage({ id: "totalHlWin" }) +
                          " : " +
                          reportState.agentDownlineReportLists.totalhlwin}
                      </td>
                    </tr>
                    <tr>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalProgressiveBet" }) +
                          " : " +
                          reportState.agentDownlineReportLists
                            .totalprogressivebet}
                      </td>
                      <td className="col-3 td-line">
                        {intl.formatMessage({ id: "totalProgressiveWin" }) +
                          " : " +
                          reportState.agentDownlineReportLists
                            .totalprogressivewin}
                      </td>
                      <td className="col-3 td-line"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end mb-2">
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/agentReport/agentDownlineReport/${auth.agentid}`, {
                state: {
                  fromDate: fromDate.toISOString(),
                  toDate: toDate.toISOString(),
                  currentAgentID: currentAgentID,
                  currentAgent: currentAgent,
                  currentDownline: auth.username,
                  isgrouptotal: "true",
                },
              });
            }}
          >
            {intl.formatMessage({ id: "totalDetails" })}
          </button>
        </div>
        {reportState.agentDownlineReportLists &&
        reportState.agentDownlineReportLists.allAgentFcGameReportsV2 &&
        reportState.agentDownlineReportLists.allAgentFcGameReportsV2.length >
          0 ? (
          reportState.agentDownlineReportLists.allAgentFcGameReportsV2.map(
            (report, index) => (
              <div
                key={index}
                className="card mb-3 card-css"
                style={{ width: "100%" }}
              >
                <div className="card-body">
                  <div className="row">
                    {report.username?.includes("Personal") ? (
                      <div className="col-12 mb-2">
                        {intl.formatMessage({ id: "username" }) + " : "}
                        <button className="buttonName">
                          {report.username}
                        </button>
                      </div>
                    ) : (
                      <div className="col-12 mb-2">
                        {intl.formatMessage({ id: "username" }) + " : "}
                        <button
                          className="buttonName"
                          onClick={() =>
                            handleClick(report.agentid, report.username)
                          }
                        >
                          {report.username}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "topup" })}</strong>
                      {" : " + report.totaltopup}
                    </div>
                    <div className="col-12 col-md-3 mb-2 text-nowrap">
                      <strong>
                        {intl.formatMessage({ id: "gatewayTopup" })}
                      </strong>
                      {" : " + report.paymentgatewaytopup}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>
                        {intl.formatMessage({ id: "refundTopup" })}
                      </strong>
                      {" : " + report.refundtopup}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>
                        {intl.formatMessage({ id: "netPurchase" })}
                      </strong>
                      {" : " + report.netpurchase}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                    }}
                  >
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "withdraw" })}</strong>
                      {" : " + report.totalwithdraw}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "gameIn" })}</strong>
                      {" : " + report.totalin}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "gameOut" })}</strong>
                      {" : " + report.totalout}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>
                        {intl.formatMessage({ id: "gameProfit" }) + " : "}
                      </strong>
                      <span
                        style={{
                          color: report.totalprofit?.includes("-")
                            ? "red"
                            : "green",
                        }}
                      >
                        {report.totalprofit}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "mktBet" })}</strong>
                      {" : " + report.totalmktbet}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "mktWin" })}</strong>
                      {" : " + report.totalmktwin}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "hlBet" })}</strong>
                      {" : " + report.totalhlbet}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>{intl.formatMessage({ id: "hlWin" })}</strong>
                      {" : " + report.totalhlwin}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 mb-2">
                      <strong>
                        {intl.formatMessage({ id: "progressiveBet" })}
                      </strong>
                      {" : " + report.totalprogressivebet}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      <strong>
                        {intl.formatMessage({ id: "progressiveWin" })}
                      </strong>
                      {" : " + report.totalprogressivewin}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate(
                            `/agentReport/agentDownlineReport/${report.agentid}`,
                            {
                              state: {
                                fromDate: fromDate.toISOString(),
                                toDate: toDate.toISOString(),
                                currentAgentID: currentAgentID,
                                currentAgent: currentAgent,
                                currentDownline: report.username,
                                isgrouptotal: "false",
                              },
                            }
                          );
                        }}
                      >
                        {intl.formatMessage({ id: "details" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <div className="card mb-3 card-css" style={{ width: "100%" }}>
            <div className="card-body">
              <div className="row">
                <div className="no-record-wrapper oRecordDiv">
                  <i className="bi bi-search fs-1 mb-3" />
                  <div className="text-font2">
                    {intl.formatMessage({ id: "noRecordFound" })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
